import { Button, SimpleGrid, Text } from '@mantine/core'
import { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          css={{
            padding: 12,
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <SimpleGrid>
            <Text
              css={{
                display: 'block',
                width: '100%',
              }}
              align="center"
              size="xl"
            >
              Упс, ошибка!
            </Text>
            <Button
              size="lg"
              onClick={() => {
                location.reload()
              }}
            >
              Перезагрузить страницу
            </Button>
          </SimpleGrid>
        </div>
      )
    }

    return this.props.children
  }
}
