import { isProduction } from "utils/isProduction";

const protocol = isProduction ? "https" : "http";
const domain = isProduction ? "kododel.ru" : "localhost:3558";

export const devPort = 3558;
export const prodPort = 7000;

export const trpcConnectString =
  typeof window == "undefined"
    ? `http://localhost:${isProduction ? prodPort : devPort}/api/trpc`
    : "/api/trpc";

export const config = {
  idYandex: isProduction ? " " : " ",
  idGoogle: isProduction ? " " : " ",
  protocol,
  domain,
  url: `${protocol}://${domain}`,
};
